import Base = require("Everlaw/Base");
import User = require("Everlaw/User");
import Perm = require("Everlaw/PermissionStrings");
import { ReviewLayout } from "Everlaw/Review/ReviewLayout"; // Circular dependency only used for type.

/*
 * Preferences, constants, and defaults for the full-screen panel
 */
class SavedReviewLayout extends Base.SecuredObject {
    get className() {
        return "SavedReviewLayout";
    }
    override id: SavedReviewLayout.Id;
    name: string;
    layout: ReviewLayout;
    updated: number;
    userId: number;
    isDefault: boolean;

    constructor(params: unknown) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: unknown) {
        Object.assign(this, params);
    }
    override display() {
        return this.name;
    }
}

module SavedReviewLayout {
    export const PROJECT_DEFAULT_INDEX = -1;
    export const PROJECT_DEFAULT_NAME = "Project default layout";
    export type Id = number & Base.Id<"SavedReviewLayout">;

    export enum LayoutType {
        PROJECT = "project",
        USER = "user",
        SHARED = "shared",
    }
    export function sortByType(
        layouts: SavedReviewLayout[],
    ): Record<LayoutType, SavedReviewLayout[]> {
        const ret = {
            [LayoutType.PROJECT]: [],
            [LayoutType.USER]: [],
            [LayoutType.SHARED]: [],
        };
        layouts.forEach((l) => ret[getType(l)].push(l));
        return ret;
    }
    export function getType(layout: SavedReviewLayout): LayoutType {
        if (layout.isDefault) {
            // Always prioritize default before user, since the default's user can be any admin.
            return LayoutType.PROJECT;
        } else if (isMine(layout)) {
            return LayoutType.USER;
        } else {
            return LayoutType.SHARED;
        }
    }
    // This sets up a substitute saved default layout to use if the project admins have not saved one yet.
    export function putDefault(allSavedLayouts: SavedReviewLayout[]): SavedReviewLayout[] {
        if (!allSavedLayouts.some((l) => l.isDefault)) {
            allSavedLayouts.push(
                new SavedReviewLayout({
                    id: PROJECT_DEFAULT_INDEX,
                    name: PROJECT_DEFAULT_NAME,
                    isDefault: true,
                }),
            );
        }
        return allSavedLayouts;
    }
    export function isMine(layout: SavedReviewLayout) {
        return User.me.id === layout.userId;
    }

    export function canAdmin(layout: SavedReviewLayout) {
        return isMine(layout) || User.me.can(Perm.ADMIN, layout);
    }
}

export = SavedReviewLayout;
