import Arr = require("Everlaw/Core/Arr");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Recipient = require("Everlaw/Recipient");
import SearchObjectPermission = require("Everlaw/Messaging/SearchSharing/SearchObjectPermission");

/**
 * Dialog to display the secured objects in search which the current user does not have share/delete
 * permission, as well as the secured object classes each recipient does not have receive permission.
 *
 * Author: Jiatao
 */
class SearchObjectPermissionConflictsDialog {
    constructor(recipient: Recipient, searchObjectPermission: SearchObjectPermission) {
        Dialog.ok(
            "Permission details",
            this.buildConflictsContainer(recipient, searchObjectPermission),
        );
    }

    private buildConflictsContainer(
        recipient: Recipient,
        searchObjectPermission: SearchObjectPermission,
    ) {
        return Dom.div(
            { class: "obj-perm-conflicts-container" },
            this.buildConflictsHeader(recipient),
            this.buildConflictsContent(recipient, searchObjectPermission),
        );
    }

    private buildConflictsHeader(recipient: Recipient) {
        return Dom.div("In order to share the search with ", recipient.display(), ":");
    }

    private buildConflictsContent(
        recipient: Recipient,
        searchObjectPermission: SearchObjectPermission,
    ) {
        const content = Dom.div({ class: "obj-perm-conflicts-content" });

        if (searchObjectPermission.hasShareableObjectClassesRecipientCannotReceive(recipient)) {
            this.displayPermissionConflicts(
                Dom.span(
                    "Recipient needs at least ",
                    Dom.b("Receive"),
                    " project level permission on the following object classes:",
                ),
                this.displayObjectClassList(
                    searchObjectPermission.getShareableObjectClassesRecipientCannotReceive(
                        recipient,
                    ),
                ),
                content,
            );
        }

        if (searchObjectPermission.hasReviewObjectClassesRecipientCannotView(recipient)) {
            this.displayPermissionConflicts(
                Dom.span(
                    "Recipient needs at least ",
                    Dom.b("View"),
                    " project level permission on the following object classes:",
                ),
                this.displayObjectClassList(
                    searchObjectPermission.getReviewObjectClassesRecipientCannotView(recipient),
                ),
                content,
            );
        }

        if (searchObjectPermission.hasSecuredCodesRecipientCannotView(recipient)) {
            this.displayPermissionConflicts(
                Dom.span(
                    "Recipient needs at least ",
                    Dom.b("View"),
                    " project level permission on the following Codes",
                    " referenced in the search:",
                ),
                this.displayObjectList(
                    searchObjectPermission.getSecuredCodesRecipientCannotView(recipient),
                ),
                content,
            );
        }

        if (searchObjectPermission.hasProjectsRecipientsCannotView(recipient)) {
            this.displayPermissionConflicts(
                Dom.span("Recipient needs access to the following project(s):"),
                this.displayProjectList(
                    searchObjectPermission.getProjectsRecipientsCannotView(recipient),
                ),
                content,
            );
        }

        if (searchObjectPermission.hasShareableObjectsSenderCannotShare(recipient)) {
            this.displayPermissionConflicts(
                Dom.span(
                    "You need ",
                    Dom.b("Share and Delete"),
                    " permission on the following objects referenced in the search:",
                ),
                this.displayObjectList(
                    searchObjectPermission.getShareableObjectsSenderCannotShare(),
                ),
                content,
            );
        }

        return content;
    }

    private displayPermissionConflicts(
        headerMsg: HTMLElement,
        elementList: HTMLElement,
        content: HTMLElement,
    ) {
        Dom.create(
            "div",
            {
                class: "obj-perm-per-type-conflicts",
                content: [headerMsg, elementList],
            },
            content,
        );
    }

    private displayObjectClassList(objectClasses: string[]) {
        const objectClassList = Dom.div({ class: "obj-perm-conflicts-list" });

        Arr.forEachOrderedUniqueElement(
            objectClasses.map((objectClass) =>
                SearchObjectPermission.displayProjectPermission(objectClass),
            ),
            (objectClass) => {
                Dom.create("li", { content: objectClass }, objectClassList);
            },
        );

        return objectClassList;
    }

    private displayProjectList(projects: string[]) {
        const projectList = Dom.div({ class: "obj-perm-conflicts-list" });

        projects.sort().forEach((project) => {
            Dom.create("li", { content: project }, projectList);
        });

        return projectList;
    }

    private displayObjectList(objectClassIdMap: { [objectClass: string]: string[] }) {
        const objectList = Dom.div({ class: "obj-perm-conflicts-list" });

        const objectClassDisplayMapArray = Object.keys(objectClassIdMap).map((objectClass) => {
            return {
                objectClassDisplay: SearchObjectPermission.displayObjectClass(objectClass),
                objectClass: objectClass,
            };
        });

        Arr.sort(objectClassDisplayMapArray, { key: (o) => o.objectClassDisplay });

        const objectClassDisplayMap = Arr.mapped(
            objectClassDisplayMapArray,
            (t) => t.objectClassDisplay,
            (t) => t.objectClass,
        );

        for (let objectClassDisplay in objectClassDisplayMap) {
            const objectClass = objectClassDisplayMap[objectClassDisplay];
            const objArr = objectClassIdMap[objectClass].sort();

            objArr.forEach((obj) => {
                Dom.create(
                    "li",
                    {
                        content: [
                            Dom.span(
                                { class: "obj-perm-conflicts-list-object-class" },
                                objectClassDisplay,
                                " - ",
                            ),
                            obj,
                        ],
                    },
                    objectList,
                );
            });
        }

        return objectList;
    }
}

export = SearchObjectPermissionConflictsDialog;
