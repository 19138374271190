import Base = require("Everlaw/Base");
import Document = require("Everlaw/Document");
import Is = require("Everlaw/Core/Is");
import User = require("Everlaw/User");
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");

export class DocRotation extends Base.Object {
    override get className(): string {
        return "DocRotation";
    }
    documentId: Document.Id;
    projectId: number;
    pageRotations: Record<number, number>;
    docView: string;
    constructor(params: any) {
        super(params);
        // IDs need to be unique. So having it just be documentId wouldn't work, since one document
        // could have one PDF, one image, and one native rotation. So concatenate it.
        this.id = `${params.documentId}${params.docView}`;
        this._mixin(params);
    }

    override _mixin(params: unknown) {
        Object.assign(this, params);
        if (Is.defined(this.id)) {
            Base.add(this);
        }
    }

    getId() {
        return this.id;
    }
}

export function canRotatePermanently(): boolean {
    return User.me.can(
        Perm.PERMANENT_ROTATIONS,
        Project.CURRENT,
        User.Override.ELEVATED_OR_ORGADMIN,
    );
}

/**
 *  After extending, the viewer names are: PDF, Native, Image, Empty, NativeImage, Text, Spreadsheet,
 *  and Media. But only the first three support rotations and are included in this map. The map takes
 *  them from their frontend names to their capitalized versions for the backend enum and SQL database.
 */
export const VIEWER_TO_ENUM = {
    PDF: "PDF",
    Native: "NATIVE",
    Image: "IMAGE",
};

export const ENUM_TO_VIEWER = Object.fromEntries(
    Object.entries(VIEWER_TO_ENUM).map(([viewer, enumVal]) => [enumVal, viewer]),
);
