import Base = require("Everlaw/Base");
import Dom = require("Everlaw/Dom");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import Widget = require("Everlaw/UI/Widget");
import { PartialSelectionType, SEL_TYPE_INFO_MAP } from "Everlaw/PartialSelectionType";
import { MirrorTooltip } from "Everlaw/UI/Tooltip";
import { NAMED_PATTERNS, PatternSpec } from "Everlaw/PersistentHighlight";

interface PartialSelectorParams {
    query: string;
    onSelect(elem: Base.Primitive<number>): void;
    initialType: PartialSelectionType;
    showLabel?: boolean;
    /**
     * If it's a search we want to make sure we don't improperly infer partial selection types
     * based off Everlaw-specific query behavior. For example the query 123 456 might seem like it
     * should display the "all but last four digits" option, but that matches text like 123 or 456.
     */
    isSearch?: boolean;
    selectorStyle?: Dom.StyleProps;
}

/**
 * A selector that automatically displays the partial redaction options for a given query
 */
export class PartialSelector extends Widget {
    private readonly selector: SingleSelect<Base.Primitive<number>>;
    private readonly query: string;

    /**
     * If the query matches a named pattern such as IP address (a regex),
     * a generic well-known pattern like <ssn>, or a specific one like <ssn=654*>,
     * this is that pattern. Undefined otherwise.
     */
    private readonly namedPattern?: PatternSpec;

    private readonly isSearch: boolean;

    constructor(params: PartialSelectorParams) {
        super();
        this.node = Dom.div();
        this.query = params.query;
        this.namedPattern = this.findNamedPattern(params.query);

        this.isSearch = !!params.isSearch;
        if (params.showLabel) {
            // don't use params.textBoxLabelContent because that will make the label have the same
            // pointer events as the textbox. That's the norm for most selectors, but we don't want
            // that behavior here because:
            //      1. We use this label in a small popover and users will often want to remain in
            //      the popover but close the selection options. Using the default label makes the
            //      area they need to click extremely small and so users will often misclick the
            //      label and then wonder why the options are still displaying
            //
            //      2. This is always paired with RedactionStamp.Creator which also doesn't have
            //      this behavior
            const label = Dom.div({ class: "partial-selector-label" }, "PII redaction type");
            Dom.place(label, this.node);
        }

        const display = (objOrName) => {
            if (typeof objOrName === "string") {
                return objOrName;
            }
            return SEL_TYPE_INFO_MAP[PartialSelectionType[objOrName.name]].display;
        };

        const tooltips: MirrorTooltip[] = [];
        const prepRowElement = (elem: Base.Primitive<number>) => {
            const previewRedact = (s: string, r: RegExp): string => {
                return s.replaceAll(r, (match) => "*".repeat(match.length));
            };
            const info = SEL_TYPE_INFO_MAP[PartialSelectionType[elem.name]];
            let subText;
            if (elem.name === PartialSelectionType.FULL) {
                subText = "Redact whole term";
            } else if (this.namedPattern) {
                subText = `e.g. ${previewRedact(this.namedPattern.example, info.selectionRegex)}`;
            } else {
                subText = `Preview: ${previewRedact(this.removeQuotes(this.query), info.selectionRegex)}`;
            }

            // Long tokens can be truncated, so we attach MirrorTooltips to the sub label
            const subTextNode = Dom.div({ class: "partial-selector-item__sub-label" }, subText);
            const tooltip = new MirrorTooltip(subTextNode);
            this.registerDestroyable(tooltip);
            tooltips.push(tooltip);

            return {
                node: Dom.div(
                    { class: "table-row action description common-option partial-selector-item" },
                    Dom.div({ class: "partial-selector-item__label" }, info.display),
                    subTextNode,
                ),
                onDestroy: [],
            };
        };

        this.selector = new SingleSelect<Base.Primitive<number>>({
            initialSelected: this.makeSelectionOption(params.initialType),
            elements: this.getSelectionOptions(),
            display,
            prepRowElement,
            comparator: (a, b) => a.id - b.id,
            selectOnSame: true,
            popup: "after",
            colorItems: null,
            matchWidth: false,
            headers: false,
            selectorStyle: params.selectorStyle,
            // dijit popups are at 1000
            zIndex: "1002",
        });
        this.registerDestroyable(this.selector);
        Dom.place(this.selector, this.node);

        this.selector.tb.setEllipsizeText(true);

        // always minimize after selecting
        this.selector.onSelect = (elem) => {
            params.onSelect(elem);
            // Default overflow behavior is ugly, even with ellipsify text since the cursor is at
            // the end of the text. We put the cursor at the beginning of the string so the
            // ellipsified text display nicely
            this.selector.tb.select(0, 0);

            // both here and in the redaction stamp selector we use minimize() instead of blur().
            // minimize plays nicely when the selector is in a popover (doesn't close automatically)
            // but this has the downside of the selector getting into a weird state after selection
            // and the user having to click outside the selector before reopening
            this.selector.minimize();

            tooltips.forEach((tooltip) => tooltip.close());
        };

        // always disable if only has one option
        if (!this.hasMultipleOptions()) {
            this.setDisabled(true);
        }
    }

    /**
     * Returns the named pattern that matches the given query, if exists. Undefined otherwise.
     * A special case is made for well-known pattern queries for specific values,
     * like <email=joe@*>, for which the named pattern for <email> is returned.
     */
    private findNamedPattern(query: string): PatternSpec {
        const patMatcher = (pat: string) => {
            if (pat === query) {
                return true;
            }
            // is it a specific well-known pattern query like "<ssn=678*>"?
            // (the generic "<ssn>" query is covered by the above check as it matches in full)
            if (!query.startsWith("<") || !pat.startsWith("<")) {
                // quick rule-out if it's not such a query, or the pattern isn't a token
                return false;
            }
            // "<ssn>" => "<ssn="
            const prefix = pat.substring(0, pat.length - 1) + "=";
            return query.startsWith(prefix);
        };

        return NAMED_PATTERNS.find((p) => patMatcher(p.regex));
    }

    private removeQuotes(query: string) {
        // Get rid of quotations at ends of string if exists
        return query.replace(/^"|"$/g, "").trim();
    }

    setValue(type: PartialSelectionType, silent = false): void {
        this.selector.setValue(this.makeSelectionOption(type), silent);
    }

    isDisabled() {
        return this.selector.isDisabled();
    }

    setDisabled(state: boolean) {
        this.selector.setDisabled(!this.hasMultipleOptions() || state);
    }

    hasMultipleOptions(): boolean {
        return this.selector.elements[0].length > 1;
    }

    private makeSelectionOption(type: PartialSelectionType): Base.Primitive<number> {
        // use order of enum to determine sort order. Alternatively I could put the index in SEL_TYPE_INFO_MAP
        return new Base.Primitive(Object.values(PartialSelectionType).indexOf(type), type);
    }

    private shouldInfer(): boolean {
        if (!this.isSearch) {
            return true;
        }
        // We only want to infer PII for searches if there is no whitespace, or it's surrounded by
        // quotes (i.e. it's not a boolean query)
        if (/\s/.test(this.query) && !/^".*"$/.test(this.query)) {
            return false;
        }

        // We also only want to infer PII for searches if there are no special Everlaw search
        // expressions. Technically this will cause some false negatives, but only in rare cases and
        // inferences on searches is just a nice to have.
        return !/[()/?*[\]~{}"]/.test(this.removeQuotes(this.query));
    }

    private getSelectionOptions(): Base.Primitive<number>[] {
        return this.getSelTypes().map((t) => this.makeSelectionOption(t));
    }

    private getSelTypes(): PartialSelectionType[] {
        const result = [PartialSelectionType.FULL];

        // if there is an empty query (ex: box redactions), end here
        if (this.query === "") {
            return result;
        }

        // if it's a named pattern it comes with its own selection options
        if (this.isSearch && this.namedPattern) {
            result.push(...this.namedPattern.partialSelectionOptions);
            return result;
        }

        // if it's not a known pattern, can we infer what type of PII it is?
        if (!this.shouldInfer()) {
            return result;
        }

        Object.keys(PartialSelectionType).forEach((t: PartialSelectionType) => {
            // we already added FULL, so we can ignore
            if (t === PartialSelectionType.FULL) {
                return;
            }

            // don't display last four chars if last four digits is already an option
            if (
                t === PartialSelectionType.NOT_LAST_FOUR_CHARS
                && result.includes(PartialSelectionType.NOT_LAST_FOUR_DIGITS)
            ) {
                return;
            }

            // only display not first char if no other options are available
            if (t === PartialSelectionType.NOT_FIRST_CHAR && result.length > 1) {
                return;
            }

            if (SEL_TYPE_INFO_MAP[t].matchRegex.test(this.removeQuotes(this.query))) {
                result.push(t);
            }
        });
        return result;
    }
}
