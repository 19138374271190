import * as Preference from "Everlaw/Preference";
import {
    ToggleSwitch,
    Span,
    DialogSize,
    Modal,
    Button,
    Checkbox,
    CheckboxGroup,
    TextButton,
} from "design-system";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { EVERID } from "Everlaw/EverAttribute/EverId";

interface PreferenceMenuProps {
    show: boolean;
    onMenuClose: () => void;
}

interface CheckboxToggle {
    /**
     * The name of the {@link Preference} that'll be displayed to the user.
     */
    label: string;
    /**
     * The {@link Preference} itself.
     */
    preference: Preference.Chain<boolean>;
}

const PREFS = Preference.RECPAGES;
const LEFT_TOGGLE: CheckboxToggle[] = [
    { label: "Default recommendations", preference: PREFS.DefaultSubset },
    { label: "Homepage", preference: PREFS.Homepage },
    { label: "Uploads", preference: PREFS.Uploads },
    { label: "Productions", preference: PREFS.Productions },
    { label: "Search", preference: PREFS.Search },
    { label: "Search term reports", preference: PREFS.SearchTermReports },
    { label: "Data visualizer", preference: PREFS.DataVisualizer },
    { label: "Predictive coding", preference: PREFS.PredictiveCoding },
    { label: "Document clustering", preference: PREFS.DocumentClustering },
];
const RIGHT_TOGGLE: CheckboxToggle[] = [
    { label: "Storybuilder", preference: PREFS.Storybuilder },
    { label: "Review window", preference: PREFS.ReviewWindow },
    { label: "Message center", preference: PREFS.MessageCenter },
    { label: "Project settings", preference: PREFS.ProjectSettings },
    { label: "Database settings", preference: PREFS.DatabaseSettings },
    { label: "Assignment groups", preference: PREFS.AssignmentGroups },
    { label: "Analytics", preference: PREFS.Analytics },
];

export const RecommendationPreferenceMenu: FC<PreferenceMenuProps> = ({ show, onMenuClose }) => {
    const [optionsToggleValue, setOptionsToggleValue] = useState<boolean>(PREFS.AllPages.get());
    const [leftToggleStates, setLeftToggleStates] = useState<boolean[]>(
        LEFT_TOGGLE.map((toggle) => toggle.preference.get()),
    );
    const [rightToggleStates, setRightToggleStates] = useState<boolean[]>(
        RIGHT_TOGGLE.map((toggle) => toggle.preference.get()),
    );

    useEffect(() => {
        if (show) {
            setOptionsToggleValue(PREFS.AllPages.get());
            setLeftToggleStates(LEFT_TOGGLE.map((toggle) => toggle.preference.get()));
            setRightToggleStates(RIGHT_TOGGLE.map((toggle) => toggle.preference.get()));
        }
    }, [show]);

    const toggleAll = (state: boolean) => {
        setLeftToggleStates(LEFT_TOGGLE.map(() => state));
        setRightToggleStates(RIGHT_TOGGLE.map(() => state));
    };

    /**
     * Commits any {@link Preference} updates the uses might have made interacting with the
     * checkboxes. This creates a rest request for every preference that has changes. In the
     * future, it may be worth considering batching updates.
     */
    const commitUpdates = () => {
        LEFT_TOGGLE.forEach((toggle, idx) =>
            toggle.preference.setUserDefault(leftToggleStates[idx]),
        );
        RIGHT_TOGGLE.forEach((toggle, idx) =>
            toggle.preference.setUserDefault(rightToggleStates[idx]),
        );
        PREFS.AllPages.setUserDefault(optionsToggleValue);
    };

    const onComplete = () => {
        onMenuClose();
        commitUpdates();
    };

    return (
        <Modal
            size={DialogSize.SMALL}
            visible={show}
            onHide={onComplete}
            onComplete={onComplete}
            primaryButton={<Button everId={EVERID.HEADER.HELP_ICON_SETTINGS_DONE}>Done</Button>}
            secondaryButton={null}
            title="Recommendation settings"
        >
            <div className="recommendation-settings v-spaced-16">
                <ToggleSwitch
                    value={optionsToggleValue}
                    onChange={() => {
                        // If we're toggling recommendations on, we should make sure at least the
                        // default set of recommendations are on. If the user has set any other
                        // preference themselves, we shouldn't do anything.
                        if (
                            !optionsToggleValue
                            && leftToggleStates.every((state) => !state)
                            && rightToggleStates.every((state) => !state)
                        ) {
                            // We only need to set the state of one toggle, the one responsible
                            // for the default subset of recommendations. Since we know that option
                            // is part of the LEFT_TOGGLE, we only need to modify the left toggle
                            // states. So, we'll create an array of left toggle states with the
                            // default subset preference set to true and the rest set to the
                            // current user selection (which we know is false given the above `if`).
                            setLeftToggleStates(
                                LEFT_TOGGLE.map((toggle, idx) => {
                                    if (toggle.preference === PREFS.DefaultSubset) {
                                        return true;
                                    }
                                    // Keep the current user selection for every other toggle.
                                    return leftToggleStates[idx];
                                }),
                            );
                        }
                        setOptionsToggleValue((value) => !value);
                    }}
                    label="Display recommendations in platform"
                />
                {optionsToggleValue && (
                    <div className="v-spaced-8">
                        <div className="flex-horizontal flex-centered">
                            <h2 className="bb-text--semibold">Display recommendations on pages</h2>
                            <Span>
                                <TextButton onClick={() => toggleAll(true)}>Select all</TextButton>
                                {"/"}
                                <TextButton onClick={() => toggleAll(false)}>
                                    Select none
                                </TextButton>
                            </Span>
                        </div>
                        <div className="recommendation-settings__checkboxes">
                            <CheckboxGroup>
                                {leftToggleStates.map((val, idx) => (
                                    <Checkbox
                                        key={idx}
                                        label={LEFT_TOGGLE[idx].label}
                                        value={val}
                                        onChange={() => {
                                            setLeftToggleStates((values) =>
                                                values.map((v, i) => (idx === i ? !v : v)),
                                            );
                                        }}
                                    />
                                ))}
                            </CheckboxGroup>
                            <CheckboxGroup className="checkbox-group__right-box">
                                {rightToggleStates.map((val, idx) => (
                                    <Checkbox
                                        key={idx}
                                        label={RIGHT_TOGGLE[idx].label}
                                        value={val}
                                        onChange={() => {
                                            setRightToggleStates((values) =>
                                                values.map((v, i) => (idx === i ? !v : v)),
                                            );
                                        }}
                                    />
                                ))}
                            </CheckboxGroup>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};
