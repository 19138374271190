import {
    ObjectData,
    ObjectListData,
    RedactionData,
    StringData,
    StringListData,
} from "Everlaw/Review/ChatViewer/Redaction/ChatRedactionData";

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.ChatSegmentMap`.
 */
export type SegmentMap = {
    version?: StringData;
    conversation?: ObjectData<ConversationMap>;
    participants: ObjectListData<ParticipantMap>[];
    events: ObjectListData<EventMap>[];
    attachments: ObjectListData<AttachmentMap>[];
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.ConversationMap`.
 */
export type ConversationMap = {
    id?: StringData;
    platform?: StringData;
    type?: StringData;
    name?: StringData;
    topic?: StringData;
    purpose?: StringData;
    index?: RedactionData;
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.ParticipantMap`.
 */
export type ParticipantMap = {
    id?: StringData;
    name?: StringData;
    phone?: StringData;
    email?: StringData;
    username?: StringData;
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap`.
 */
export type EventMap = {
    id?: StringData;
    participant?: StringData;
    type?: StringData;
    timestamp?: StringData;
    direction?: StringData;
    parent?: StringData;
    deleted?: RedactionData;
    blocks: ObjectListData<BlockMap>[];
    edits: ObjectListData<EditMap>[];
    attachments: StringListData[];
    reactions: ObjectListData<ReactionMap>[];
    disclaimer?: StringData;
    statuses: ObjectListData<StatusMap>[];
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap.BlockMap`.
 */
export type BlockMap = {
    text?: StringData;
    url?: StringData;
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap.EditMap`.
 */
export type EditMap = {
    timestamp?: StringData;
    blocks: ObjectListData<BlockMap>[];
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap.ReactionMap`.
 */
export type ReactionMap = {
    emoji?: StringData;
    participant?: StringData;
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap.StatusMap`.
 */
export type StatusMap = {
    type?: StringData;
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.AttachmentMap`.
 */
export type AttachmentMap = {
    id?: StringData;
    title?: StringData;
    fallback?: StringData;
    filename?: StringData;
    path?: StringData;
    size?: RedactionData;
    type?: StringData;
    url?: StringData;
};

/**
 * Create a redaction mapping representing a full redaction on a chat event.
 */
export function createFullEventRedactionMapping(eventIndex: number): SegmentMap {
    const eventData: ObjectListData<EventMap> = { fullyRedacted: true, index: eventIndex };
    return { participants: [], events: [eventData], attachments: [] };
}

/**
 * If the given mapping represents an event redaction, fetch the index of that event within its
 * segment.
 */
export function getEventIndex(segmentMap: SegmentMap): number | undefined {
    return segmentMap.events[0]?.index;
}

/**
 * If the given mapping represents an event redaction, is it a full redaction?
 */
export function isFullEventRedaction(segmentMap: SegmentMap): boolean {
    return !!segmentMap.events[0]?.fullyRedacted;
}
