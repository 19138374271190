import {
    Counter,
    EverColor,
    Icon,
    IconButton,
    Popover,
    PopoverPlacement,
    Tooltip,
} from "design-system";
import { ErrorBoundary } from "Everlaw/ErrorBoundary";
import { EVERID } from "Everlaw/EverAttribute/EverId";
import { AllRecommendationsTable } from "Everlaw/SmartOnboarding/Components/AllRecommendationsTable";
import { ContactDialog } from "Everlaw/SmartOnboarding/Components/ContactDialog";
import { HelpMenuOption } from "Everlaw/SmartOnboarding/Components/HelpMenuOption";
import {
    MenuRowRecommendationDisplay,
    onRecommendationClick,
    recommendationSort,
} from "Everlaw/SmartOnboarding/Components/RecommendationDisplay";
import { Resources } from "Everlaw/SmartOnboarding/Components/RecommendationHelpResources";
import { RecommendationPreferenceMenu } from "Everlaw/SmartOnboarding/Components/RecommendationPreferenceMenu";
import {
    getRecommendationsWithPermissions,
    Recommendations,
} from "Everlaw/SmartOnboarding/RecommendationConstants";
import {
    fromCurrentPage,
    isSubpageOrAncestorOf,
} from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import * as React from "react";
import {
    FC,
    MutableRefObject,
    RefObject,
    useId,
    useImperativeHandle,
    useRef,
    useState,
} from "react";

interface HelpMenuProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowOptionsRef: MutableRefObject<(show: boolean) => void>;
    popoverTarget: RefObject<Element>;
}

function calculateUnviewedRecommendations(): number {
    return getRecommendationsWithPermissions().reduce((acc, recommendation) => {
        if (recommendation.isNotViewedUnsafe()) {
            return acc + 1;
        }
        return acc;
    }, 0);
}

interface ViewAllRecommendationsProps {
    onClick: () => void;
}

const ViewAllRecommendations: FC<ViewAllRecommendationsProps> = ({ onClick }) => {
    const [showAllRecommendations, setShowAllRecommendations] = useState<boolean>(false);
    const unviewedRecommendations = calculateUnviewedRecommendations();
    const tooltipRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    return (
        <div className="help-menu__view-all-recommendations">
            <HelpMenuOption
                selectable={false}
                label={
                    <div className="flex-centered clickable-row">
                        <div className="bb-text--color-link">View all recommendations</div>
                        <div
                            ref={tooltipRef}
                            tabIndex={0}
                            className="help-menu__unviewed-recommendation-counter bb-focus-visible-pseudo"
                            aria-label={unviewedRecommendations + " recommendations are unviewed"}
                        >
                            <Counter>{unviewedRecommendations}</Counter>
                        </div>
                        <Tooltip target={tooltipRef}>
                            {unviewedRecommendations} unviewed recommendations
                        </Tooltip>
                        <AllRecommendationsTable
                            show={showAllRecommendations}
                            onMenuClose={() => setShowAllRecommendations(false)}
                        />
                    </div>
                }
                onClick={() => {
                    onClick();
                    setShowAllRecommendations(true);
                }}
            />
        </div>
    );
};

/**
 * The three {@link Recommendation Recommendations} we'll show in the help menu, based on current
 * page. Only recommendations that have been triggered are allowed to be shown in the menu.
 */
const SmartRecommendations: FC = () => {
    const supportedPage = fromCurrentPage();
    if (supportedPage === null) {
        throw new Error("Trying to render the help menu on an unsupported page!");
    }
    const rows = getRecommendationsWithPermissions()
        // A recommendation's supported pages may contain pages that aren't really associated with
        // the recommendation, and the navigation page associated with
        // `RecommendationPreferencePage` may not be specific enough to capture `supportedPage`.
        // Thus, `navigationPage` most accurately represents the `RecommendationNavigationPage`
        // we should consider here.
        .filter(
            (rec) =>
                rec.isTriggeredUnsafe() && isSubpageOrAncestorOf(supportedPage, rec.navigationPage),
        )
        .sort(recommendationSort)
        .slice(0, 3)
        .map((rec) => (
            <HelpMenuOption
                selectable={false}
                label={<MenuRowRecommendationDisplay recommendation={rec} />}
                onClick={() => onRecommendationClick(rec.getRecommendationKey())}
                key={rec.getRecommendationKey()}
            />
        ));
    return <div className="smart-recommendations">{rows}</div>;
};

export const RecommendationHelpMenu: FC<HelpMenuProps> = ({
    show,
    setShow,
    setShowOptionsRef,
    popoverTarget,
}) => {
    const [showContact, setShowContact] = useState<boolean>(false);

    const triggerRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    useImperativeHandle(
        setShowOptionsRef,
        () => {
            return setShowOptions;
        },
        [],
    );
    const lightningIconRef = useRef(null);
    const popoverHeaderId = useId();
    const lightningIconTooltipId = useId();

    const footer = (
        <>
            <div className="help-menu-footer">
                <div className="bb-text--overline bb-text--color-secondary">RESOURCES</div>
                <Resources
                    helpMenuShow={show}
                    onViewAllResourcesClick={() => {
                        setShow(false);
                        import("Everlaw/HelpOverlay").then((HelpOverlay) => HelpOverlay.show());
                    }}
                    onContactSupportClick={() => {
                        setShow(false);
                        setShowContact(true);
                    }}
                />
                <ContactDialog show={showContact} onDialogClose={() => setShowContact(false)} />
            </div>
        </>
    );

    const recommendationsHeader = (
        <div className="flex-centered">
            <div className="bb-text--overline bb-text--color-secondary" id={popoverHeaderId}>
                RECOMMENDATIONS
            </div>
            <Icon.BoltFilled
                aria-label="Info"
                color={EverColor.EVERBLUE_50}
                ref={lightningIconRef}
                size={16}
                aria-describedby={lightningIconTooltipId}
                tabIndex={0}
                className="help-menu__bolt-icon"
            />
            <Tooltip id={lightningIconTooltipId} target={lightningIconRef}>
                Recommendations listed here will be refreshed based on the current page
            </Tooltip>
            <IconButton
                aria-label="Show Preference Settings"
                ref={triggerRef}
                everId={EVERID.HEADER.HELP_ICON_SETTINGS}
                onClick={() => {
                    setShow(false);
                    setShowOptions(true);
                }}
                className="gear-icon"
            >
                <Icon.Settings size={20} />
            </IconButton>
            <Tooltip target={triggerRef} aria-hidden={true}>
                Recommendation settings
            </Tooltip>
        </div>
    );

    Recommendations.WELCOME_TO_EVERLAW.getStep(2).reregisterDisplayer({
        node: EVERID.HEADER.HELP_ICON_SETTINGS_DONE,
        placement: [PopoverPlacement.LEFT_START, PopoverPlacement.LEFT_END],
        nextFunction: () => {
            setShowOptionsRef.current(false);
        },
        nextFunctionInverse: () => {
            setShowOptionsRef.current(true);
        },
    });

    return (
        <>
            <Popover
                show={show}
                setShow={setShow}
                target={popoverTarget}
                placement={[PopoverPlacement.LEFT_END, PopoverPlacement.BOTTOM_END]}
                aria-labelledby={popoverHeaderId}
                footer={footer}
            >
                <div className="help-menu">
                    {recommendationsHeader}
                    <ErrorBoundary>
                        <SmartRecommendations />
                    </ErrorBoundary>
                    <ViewAllRecommendations onClick={() => setShow(false)} />
                    <RecommendationPreferenceMenu
                        show={showOptions}
                        onMenuClose={() => setShowOptions(false)}
                    />
                </div>
            </Popover>
        </>
    );
};
