import { Object as Obj } from "Everlaw/Base";
import { Id } from "Everlaw/User";

export interface S3SourceRestData {
    id: number;
    filename: string;
    length: number;
    s3UploadId?: string;
    userId: number;
    state: string;
}

/**
 * Contains upload config and S3 source file information for a file uploaded
 * through MultipartUploads.
 * A front-end correspondence of S3Source.java.
 */
export abstract class S3Source extends Obj {
    override id: number;
    filename: string;
    length: number;
    s3UploadId: string;
    userId: Id;
    state: SourceState;
    progress: ProgressInfo;
    abstract signPart(partNumber: number): Promise<string>;
    abstract completeUpload(etags: string[]): Promise<void>;

    constructor(data: S3SourceRestData) {
        super(data);
        this._mixin(data);
    }

    override _mixin(data: S3SourceRestData): void {
        Object.assign(this, data);
        this.state = data.state as SourceState;
    }
}

export enum SourceState {
    // In the UI, UPLOADED is "Received"
    UPLOADING = "UPLOADING",
    UPLOADED = "UPLOADED",
    PENDING = "PENDING",
    PROCESSING = "PROCESSING",
    COMPLETED = "COMPLETED",
    WILL_NOT_COMPLETE = "WILL_NOT_COMPLETE",
    ERROR = "ERROR",
}

interface ProgressInfo {
    completed: number; // Bytes completed
    rate?: number; // Upload rate, in bytes/millisec
    eta?: number; // Estimated time till complete, used when something external (like a task) already has eta calculated
}
