import Base = require("Everlaw/Base");
import BaseObjectList = require("Everlaw/UI/BaseObjectList");
import BaseSingleSelect = require("Everlaw/UI/BaseSingleSelect");
import SingleSelect = require("Everlaw/UI/SingleSelect");

class ObjectList<OBJ extends Base.Object> extends BaseObjectList<OBJ> {
    constructor(params: BaseObjectList.Params<OBJ>) {
        super(params);
    }

    constructSelector(params: BaseSingleSelect.Params<OBJ>): SingleSelect<OBJ> {
        return new SingleSelect<OBJ>(params);
    }
}

export = ObjectList;
