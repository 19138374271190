import Is = require("Everlaw/Core/Is");
import { NoteUtil } from "Everlaw/Note";
import { Redaction } from "Everlaw/Redaction";
import RedactionStamp from "Everlaw/Review/RedactionStamp";

export const Display = {
    normal: "",
    redact: "Redact",
    keepFormula: "KeepFormula",
    keepValue: "KeepValue",
    dontRedact: "DontRedact",
};

export interface SpreadsheetRedactionCoord {
    sheet: number;
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface CellRange {
    sheet: number;
    startX: number;
    startY: number;
    endX: number;
    endY: number;
}

export abstract class AbstractSpreadsheetRedaction extends Redaction {
    className: string;
    sheet: number;
    override id: number;
    override redactionStamp: RedactionStamp;
    dependentIds: number[] = [];
    usedDependency: boolean;
    displayType: string;
    pivotTableSources: string;
    x: number;
    y: number;
    width: number;
    height: number;

    override getStampSize(stamp: RedactionStamp): number {
        // Font size of the stamp doesn't apply, just return an arbitrary truthy value
        return 1;
    }

    /**
     * This method does nothing. Spreadsheet redactions are persisted via the Manager.
     */
    commit(): Promise<AbstractSpreadsheetRedaction> {
        return Promise.resolve(this);
    }

    abstract isRoot(): boolean;
    abstract isDependency(): boolean;

    abstract contains(range: CellRange): boolean;

    abstract isPivotTableRedaction(): boolean;
}
AbstractSpreadsheetRedaction.prototype.className = "AbstractSpreadsheetRedaction";

export class SpreadsheetRedaction
    extends AbstractSpreadsheetRedaction
    implements SpreadsheetRedactionCoord
{
    get redactionType(): NoteUtil.ParentType {
        return NoteUtil.ParentType.SpreadsheetRedaction;
    }
    precedentId: number;
    isPivot: boolean;

    contains(range: CellRange): boolean {
        return (
            this.sheet === range.sheet
            && this.x <= range.startX
            && this.y <= range.startY
            && this.x + this.width > range.endX
            && this.y + this.height > range.endY
        );
    }

    isRoot(): boolean {
        return !Is.defined(this.precedentId);
    }

    isDependency(): boolean {
        return Is.defined(this.precedentId);
    }

    override isPivotTableRedaction(): boolean {
        return this.isPivot;
    }
}
SpreadsheetRedaction.prototype.className = "SpreadsheetRedaction";

export class FsiSpreadsheetRedaction extends AbstractSpreadsheetRedaction {
    get redactionType(): NoteUtil.ParentType {
        return NoteUtil.ParentType.FsiSpreadsheetRedaction;
    }
    rectangles: InverseSpreadsheetRedactionRectangle[];
    override displayType = Display.redact;

    constructor(params: any) {
        super(params);
        if (!this.rectangles) {
            this.rectangles = [];
        }
    }

    override _mixin(params: any) {
        super._mixin(params);
        this.rectangles = [];
        if (Is.defined(params.rectangles)) {
            params.rectangles.forEach((rect) => {
                this.rectangles.push(
                    new InverseSpreadsheetRedactionRectangle(
                        rect.x,
                        rect.y,
                        rect.width,
                        rect.height,
                        rect.redactionId,
                    ),
                );
            });
        }
    }

    isRoot(): boolean {
        return true;
    }

    isDependency(): boolean {
        return false;
    }

    contains(cellRange: CellRange): boolean {
        return (
            this.sheet === cellRange.sheet && this.rectangles.every((r) => !r.overlaps(cellRange))
        );
    }

    override isPivotTableRedaction(): boolean {
        return false;
    }
}
FsiSpreadsheetRedaction.prototype.className = "FsiSpreadsheetRedaction";

export class InverseSpreadsheetRedactionRectangle {
    x: number;
    y: number;
    width: number;
    height: number;
    redactionId: number;

    constructor(x: number, y: number, width: number, height: number, redactionId: number | null) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.redactionId = redactionId;
    }

    overlaps(cellRange: CellRange): boolean {
        // Two rectangles do not overlap if the minimum in either dimension for one of them is
        // greater than the other's maximum in that dimension.
        const noOverlap =
            this.x > cellRange.endX
            || cellRange.startX > this.x + this.width
            || this.y > cellRange.endY
            || cellRange.startY > this.y + this.height;
        return !noOverlap;
    }
}
