import Bugsnag = require("Everlaw/Bugsnag");
import Core = require("Everlaw/Core");
import * as ChronologyObject from "Everlaw/Chron/Doc/ChronologyObject";
import * as DepositionHighlight from "Everlaw/Depo/DepositionHighlight";
import { TranscriptVideoStateData } from "Everlaw/Depo/TranscriptVideoManager";
import Project = require("Everlaw/Project");

/** Core channels used on the depo page. */
export const CHANNEL = {
    /**
     * A channel when new TranscriptHighlight entities have been persisted (or removed) and
     * registered in the HighlightManager.
     */
    highlightsUpdated: new Core.Channel<{
        highlights: DepositionHighlight[];
        removed: boolean;
        focus?: boolean;
    }>(),
    /**
     * A channel when highlights are filtered from the highlight tab.
     */
    highlightsFiltered: new Core.Channel<{ highlights: DepositionHighlight[] }>(),
    /**
     * A channel for when the currently active highlight (indicated by a dark border around its
     * rectaangles) changes.
     */
    currentHighlight: new Core.Channel<{
        highlight?: DepositionHighlight;
        scroll?: boolean;
        play?: boolean;
    }>(),
    /**
     * A channel for starting the edit of an existing highlight's range.
     * If no parameter highlight, we are stopping editing highlight range.
     */
    editHighlightRange: new Core.Channel<{ highlight?: DepositionHighlight }>(),
    /**
     * A channel for resuming the edit of an existing highlight's range.
     */
    resumeEditHighlightRange: new Core.Channel<{ highlight: DepositionHighlight }>(),
    /**
     * A channel for updating the range and timestamps of an existing highlight.
     */
    updateHLRangeWithVideo: new Core.Channel<{}>(),
    /**
     * A channel for updating the timestamps range of an existing highlight.
     */
    selectHighlightTimeRange: new Core.Channel<{
        highlight: DepositionHighlight;
        timeRange: DepositionHighlight.TranscriptVideoTimeRange;
        originalTimestamps: DepositionHighlight.TranscriptVideoTimeRange;
        highlightRangeEdited: boolean;
        isHighlightUpdateNotification: boolean;
    }>(),
    /**
     * A channel for publishing highlight's video time range that is edited (in TranscriptClipEditor).
     * Provide null if resetting the highlight to its original timestamps.
     */
    editHighlightTimeRange: new Core.Channel<{
        timeRange?: DepositionHighlight.TranscriptVideoTimeRange;
    }>(),
    /**
     * A channel for updating widgets upon transcript video updates.
     */
    transcriptVideoUpdated: new Core.Channel<{}>(),
    /**
     * A channel for updating warning widget upon transcript video upload encountering error.
     */
    transcriptVideoError: new Core.Channel<TranscriptVideoStateData>(),
    /**
     * A channel for updating warning widget upon transcript video upload encountering error.
     */
    playTestimonyVideo: new Core.Channel<{ id: ChronologyObject.Id }>(),
};

export interface DepoUrlParams {
    depoId: number;
    chronObjId?: number;
    playVideo?: boolean;
    exhibitName?: string;
    chatActive?: boolean;
    search?: string;
}

// We have hit a couple Bugsnag errors where elements defined in deposition.jsp are not available
// in the DOM when fetched by DepositionDisplayer (which is created following the successful
// multiplex subscription to deposition/getFull.rest). The hope is that these logs may help with
// debugging what is going when this happens.
export function getDepoHeaderContainer(): HTMLElement | null {
    const elem = document.getElementById("depo-header");
    if (!elem) {
        Bugsnag.notify(Error("Attempt to fetch non-existent 'depo-header' element"));
    }
    return elem;
}

// We have hit a couple Bugsnag errors where elements defined in deposition.jsp are not available
// in the DOM when fetched by DepositionDisplayer (which is created following the successful
// multiplex subscription to deposition/getFull.rest). The hope is that these logs may help with
// debugging what is going when this happens.
export function getDepoFullScreenPanelContainer(): HTMLElement | null {
    const elem = document.getElementById("modules-container");
    if (!elem) {
        Bugsnag.notify(Error("Attempt to fetch non-existent 'modules-container' element"));
    }
    return elem;
}

export function buildDepoUrl(params: DepoUrlParams): string {
    return Project.CURRENT.urlFor("deposition", params);
}

export function buildCreateDepoUrl(chronId?: number): string {
    const hash: any = {};
    if (chronId) {
        hash.chronId = chronId;
    }
    return Project.CURRENT.urlFor("deposition", hash);
}

export interface DepoPageHash {
    depoId?: number;
    versionId?: number;
    chronObjId?: number;
    playVideo?: boolean;
    chatActive?: boolean;
    search?: string;
    chronId?: number;
}
