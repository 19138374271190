import { Button, ButtonColor, ButtonSize, CommonIcon, Paragraph, TextButton } from "design-system";
import * as Dom from "Everlaw/Dom";
import { getSupportPartnerAndOrganization } from "Everlaw/Help";
import * as MessageComposer from "Everlaw/Messaging/MessageComposer";
import * as NewMessage from "Everlaw/Messaging/NewMessage";
import { projectIdString } from "Everlaw/SmartOnboarding/Components/ContactDialog";
import * as User from "Everlaw/User";
import * as React from "react";
import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

interface MessageComposerContentProps {
    onSubmit: () => void;
    onCancel: () => void;
    onSendComplete: (success: boolean) => void;
}

const MessageComposerContent: FC<MessageComposerContentProps> = ({
    onSubmit,
    onCancel,
    onSendComplete,
}) => {
    const [sendDisabled, setSendDisabled] = useState(true);
    const messageComposerContainer: MutableRefObject<HTMLDivElement> = useRef(Dom.div());
    const messageComposer: MutableRefObject<NewMessage.Composer | undefined> =
        useRef<NewMessage.Composer>();
    useEffect(() => {
        const { supportPartner } = getSupportPartnerAndOrganization();
        MessageComposer.compose(
            {
                to: supportPartner ? supportPartner.organization.sid() : User.byName("Everlaw"),
                subject: `Requesting support for project (ID: ${projectIdString})`,
                onSendComplete,
            },
            { parentNode: messageComposerContainer.current, disableSubmit: setSendDisabled },
        ).then((composer) => {
            messageComposer.current = composer;
            composer.editor.show();
        });
    }, [onSendComplete]);
    const submitMessage = useCallback(() => {
        onSubmit();
        messageComposer.current?.onSubmit();
    }, [onSubmit]);
    const cancelMessage = useCallback(() => {
        onCancel();
        messageComposer.current?.onCancel();
    }, [onCancel]);
    return (
        <div className="message-composer v-spaced-16">
            <div className="message-composer__container" ref={messageComposerContainer}></div>
            <div className="message-composer__buttons h-spaced-12 flex-end">
                <Button
                    onClick={cancelMessage}
                    size={ButtonSize.SMALL}
                    color={ButtonColor.SECONDARY}
                    minWidth="96px"
                >
                    Cancel
                </Button>
                <Button
                    onClick={submitMessage}
                    size={ButtonSize.SMALL}
                    disabled={sendDisabled}
                    minWidth="96px"
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

interface PlatformMessageComposerProps {
    showMessageComposer: boolean;
    hideMessageComposer: () => void;
    displayMessageComposer: () => void;
}

export const PlatformMessageComposer: FC<PlatformMessageComposerProps> = ({
    showMessageComposer,
    hideMessageComposer,
    displayMessageComposer,
}) => {
    const [sendComplete, setSendComplete] = useState<boolean | undefined>();
    return (
        <div className="platform-message-composer">
            {!showMessageComposer && sendComplete === undefined && (
                <TextButton onClick={displayMessageComposer}>
                    Send a message to Support on Everlaw
                </TextButton>
            )}
            {sendComplete !== undefined && (
                <div className="platform-message-composer__completion-message">
                    {sendComplete && (
                        <Paragraph className="gray-text">Successfully sent message</Paragraph>
                    )}
                    {!sendComplete && (
                        <div>
                            <CommonIcon.ErrorTriangle size={20} aria-label="Error">
                                Error sending message
                            </CommonIcon.ErrorTriangle>
                        </div>
                    )}
                </div>
            )}
            {showMessageComposer && sendComplete === undefined && (
                <MessageComposerContent
                    onSubmit={hideMessageComposer}
                    onCancel={hideMessageComposer}
                    onSendComplete={(success) => setSendComplete(success)}
                />
            )}
        </div>
    );
};
