import * as Base from "Everlaw/Base";
import * as Is from "Everlaw/Core/Is";
import { NoteUtil } from "Everlaw/Note";
import { Redaction } from "Everlaw/Redaction";
import * as Rest from "Everlaw/Rest";
import * as ChatRedactionMap from "Everlaw/Review/ChatViewer/Redaction/ChatRedactionMap";

interface ChatRedactionParams {
    redactionMap: ChatRedactionMap.SegmentMap;
}

/**
 * Frontend class representing a native chat redaction for our work-in-progress chat viewer.
 *
 * @author abhinavsingh
 */
export default class ChatRedaction extends Redaction {
    private redactionMap: ChatRedactionMap.SegmentMap;

    override _mixin(params: ChatRedactionParams): void {
        super._mixin(params);
    }

    override get className(): string {
        return "ChatRedaction";
    }

    override async commit(): Promise<void> {
        const data = await Rest.post("documents/saveChatRedaction.rest", {
            docId: this.docId,
            chatRedactionId: this.id,
            stampId: this.redactionStamp.id,
            redactionJson: JSON.stringify(this.redactionMap),
        });
        if (!Is.number(this.id)) {
            this.id = data.id;
            Base.add(this);
        }
        this._mixin(data);
        Base.publish(this);
    }

    remove(): void {
        if (Is.number(this.id)) {
            Rest.post("documents/deleteChatRedaction.rest", { chatRedactionId: this.id }).then(() =>
                Base.remove(this),
            );
        }
    }

    override get redactionType(): NoteUtil.ParentType {
        return NoteUtil.ParentType.ChatRedaction;
    }

    /**
     * If this redaction is an event redaction, fetch the index of the event within its chat
     * segment.
     */
    getEventIndex(): number | undefined {
        return ChatRedactionMap.getEventIndex(this.redactionMap);
    }

    /**
     * If this redaction is an event redaction, is it a full redaction?
     */
    isFullEventRedaction(): boolean {
        return ChatRedactionMap.isFullEventRedaction(this.redactionMap);
    }
}
