import { clsx } from "clsx";
import { IconButton, IconButtonParams } from "Everlaw/UI/Button";
import ComboButton from "Everlaw/UI/ComboButton";
import Dom = require("Everlaw/Dom");
import PopoverMenu = require("Everlaw/UI/PopoverMenu");
import * as Widget from "Everlaw/UI/Widget";

export type SplitIconButtonMainButtonProps = Omit<IconButtonParams, "onClick"> & {
    onClick?: () => void;
};

/**
 * An imperative version of our design system SplitIconButton, which is an icon button split
 * up into a main button and a dropdown button. The main button performs some primary action,
 * while the dropdown button opens a PopoverMenu with secondary actions.
 *
 * SplitIconButton should be used instead of ComboButton whenever the main button is an icon.
 */
export class SplitIconButton extends Widget {
    mainButton: IconButton;
    onMainButtonClick?: () => void;
    dropdownButton: IconButton;
    menu: PopoverMenu;

    constructor(buttonParams: SplitIconButtonMainButtonProps, menuParams: ComboButton.MenuParams) {
        super();
        this.mainButton = new IconButton({
            ...buttonParams,
            className: clsx(buttonParams.className, "bb-split-icon-button__main-button"),
        });
        this.onMainButtonClick = buttonParams.onClick;
        this.dropdownButton = new IconButton({
            className: "bb-split-icon-button__dropdown-button",
            iconClass: "caret-down-16",
            ariaLabel: menuParams.tooltip,
        });
        if (!menuParams.orient) {
            menuParams.orient = ["below-centered", "above-centered"];
        }
        menuParams.makeFocusable = true;
        menuParams.focusStyling = "focus-with-space-style";
        this.menu = new PopoverMenu(menuParams, Dom.node(this.dropdownButton));
        this.node = Dom.div(
            { class: "bb-split-icon-button" },
            Dom.node(this.mainButton),
            Dom.node(this.dropdownButton),
        );
        if (menuParams.labelClass) {
            Dom.addClass(this.node, menuParams.labelClass);
        }
        this.registerDestroyable(this.menu);
        this.registerDestroyable(this.node);
    }

    setMainIconClass(newMainIconClass: string) {
        this.mainButton.setIconClass(newMainIconClass);
    }

    setDisabled(state: boolean) {
        this.mainButton.setDisabled(state);
        this.dropdownButton.setDisabled(state);
        this.menu.setDisabled(state);
    }

    isToggled() {
        return Dom.hasClass(this.mainButton, "bb-icon-button--active");
    }

    setToggled(state: boolean) {
        Dom.setAttr(this.mainButton, "aria-pressed", String(state));
        Dom.toggleClass(this.mainButton, "bb-icon-button--active", state);
    }
}
