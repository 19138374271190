import Base = require("Everlaw/Base");

class UserResultsTableView extends Base.Object {
    columnWidths: [string, string, number][];
    get className() {
        return "UserResultsTableView";
    }
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
}

export = UserResultsTableView;
