import { CompletionStatus } from "Everlaw/LLM/LLM";
import * as User from "Everlaw/User";

export const draftsPerPage = 10;

/** Should align with the non-deprecated Writing Assistant Tasks from LLMTaskType.java */
export enum WritingAssistantTaskType {
    WRITING_ASSISTANT_MEMO = "WRITING_ASSISTANT_MEMO",
    WRITING_ASSISTANT_OUTLINE = "WRITING_ASSISTANT_OUTLINE",
    WRITING_ASSISTANT_LIST = "WRITING_ASSISTANT_LIST",
    WRITING_ASSISTANT_REWRITE = "WRITING_ASSISTANT_REWRITE",
    WRITING_ASSISTANT_TABLE = "WRITING_ASSISTANT_TABLE",
}

export type WritingAssistantTaskSelectionOptions = Exclude<
    WritingAssistantTaskType,
    WritingAssistantTaskType.WRITING_ASSISTANT_REWRITE
>;

/**
 * See WritingAssistantTaskParams.java
 * When adding a value here, be sure to add the key as a String in the value array for
 * the appropriate corresponding tasks in TASK_TO_PARAM_MAP.
 */
export interface WritingAssistantTaskParams {
    itemType?: string;
    itemInfo?: string;
    listOrder?: DraftingListOrder;
    memoType?: DraftingType;
    outlineType?: DraftingType;
    userInstructions?: string;
    sourceText?: string;
    columns?: string[];
}

/** See WritingAssistantCompletion.java */
export interface WritingAssistantCompletion {
    id: number;
    userId: User.Id;
    timestamp: number;
    evidenceType: ArgumentEvidenceType;
    taskType: WritingAssistantTaskType;
    status: CompletionStatus;
    paidFor: boolean;
    cost: number;
}

/**
 * A map of each Writing Assistant task to their corresponding Task Params.
 * Key: Each Writing Assistant Task Type
 * Value: An array of task params (keys of the WritingAssistantTaskParams object) that are relevant
 * & used by the given task. Tasks can share params (task param keys can be in multiple value arrays).
 */
export const TASK_TO_PARAM_MAP: Record<
    WritingAssistantTaskType,
    Array<keyof WritingAssistantTaskParams>
> = {
    [WritingAssistantTaskType.WRITING_ASSISTANT_MEMO]: ["memoType", "userInstructions"],
    [WritingAssistantTaskType.WRITING_ASSISTANT_OUTLINE]: ["outlineType", "userInstructions"],
    [WritingAssistantTaskType.WRITING_ASSISTANT_LIST]: ["itemType", "itemInfo", "listOrder"],
    [WritingAssistantTaskType.WRITING_ASSISTANT_TABLE]: ["itemType", "columns"],
    [WritingAssistantTaskType.WRITING_ASSISTANT_REWRITE]: ["userInstructions", "sourceText"],
};

/** See WritingAssistantGeneration.java */
export interface WritingAssistantGeneration {
    taskParams: WritingAssistantTaskParams;
    chronLabelIds?: number[];
    finalDraft: string | undefined;
    error?: string;
}

export interface WritingAssistantCompletionAndGeneration {
    completion: WritingAssistantCompletion;
    generation: WritingAssistantGeneration;
}

export interface PaginatedDrafts {
    drafts: WritingAssistantCompletionAndGeneration[];
    totalCompletions: number;
    users: number[];
}

export interface SourceCount {
    eligible: number;
    total: number;
}

export enum DraftingType {
    ANALYZE = "ANALYZE",
    ARGUE = "ARGUE",
}

export enum DraftingListOrder {
    CHRONOLOGICAL = "CHRONOLOGICAL",
    REVERSE_CHRONOLOGICAL = "REVERSE_CHRONOLOGICAL",
    NONE = "NONE",
}

/** Defining Entity Types and Classes Specific to the WritingAssistant */
export enum ArgumentEvidenceType {
    ARG = "ARG",
    CHRON = "CHRON",
    LABEL = "LABEL",
    NONE = "NONE",
}

export const ArgumentEvidenceToString: Record<ArgumentEvidenceType, string> = {
    [ArgumentEvidenceType.ARG]: "Evidence in this Draft",
    [ArgumentEvidenceType.CHRON]: "All Story evidence",
    [ArgumentEvidenceType.LABEL]: "Evidence with label",
    [ArgumentEvidenceType.NONE]: "No additional evidence",
};

export const WritingAssistantTaskTitleString: Record<WritingAssistantTaskSelectionOptions, string> =
    {
        [WritingAssistantTaskType.WRITING_ASSISTANT_MEMO]: `memo`,
        [WritingAssistantTaskType.WRITING_ASSISTANT_OUTLINE]: `outline`,
        [WritingAssistantTaskType.WRITING_ASSISTANT_LIST]: `list`,
        [WritingAssistantTaskType.WRITING_ASSISTANT_TABLE]: `table`,
    };

// An empty task configuration that marks the initial state for the user selection (or the default
// when a template is unselected).
export const DEFAULT_TASK_CONFIG: Required<WritingAssistantTaskParams> = Object.freeze({
    itemType: "",
    itemInfo: "",
    listOrder: DraftingListOrder.NONE,
    memoType: DraftingType.ANALYZE,
    outlineType: DraftingType.ANALYZE,
    userInstructions: "",
    sourceText: "",
    columns: [],
});
