import * as React from "react";
import { getReactPlugin } from "Everlaw/Bugsnag";
import { Paragraph } from "design-system";

export interface FallbackComponentProps {
    /**
     * The specific error that was encountered.
     */
    error?: Error;
    /**
     * Information about the error.
     */
    info?: React.ErrorInfo;
    /**
     * A function which will re-render the child tree. This is supplied by our BugsnagErrorBoundary
     * automatically and should not be overridden.
     */
    clearError?: () => void;
}

interface DefaultFallbackComponentProps extends FallbackComponentProps {
    /**
     * An optional message to be used for the fallback component if you wish to override the default
     * message. It should be used only where appropriate to avoid inconsistency in messaging.
     */
    message?: string;
    /**
     * An optional class name to be added to the fallback component.
     */
    className?: string;
}

interface ErrorBoundaryProps {
    /**
     * An optional component that will render instead of the child component on an error. If this
     * parameter is not specified, a default component will be used for the fallback. The default
     * component should be favored so that we have consistency across our ErrorBoundarys. An
     * optional message parameter can be supplied to the default fallback if you wish to change the
     * default error message. Again, this parameter should be used sparingly to maintain consistency
     * in our error messages. See ReactStylePage.tsx for examples of implementation.
     *
     * The FallbackComponent takes clearError as a prop. This is supplied automatically by
     * our Bugsnag implementation. Calling clearError() will cause a re-render of the child tree
     * instead of the FallbackComponent.
     *
     * Because the child component will not render, the FallbackComponent should be styled so that
     * the page layout is appropriate in the event of an error.
     */
    FallbackComponent?: React.FC<FallbackComponentProps>;
    /**
     * The component to be wrapped in an ErrorBoundary.
     */
    children: React.ReactNode;
}

// Instantiate the Bugsnag ErrorBoundary
// https://docs.bugsnag.com/platforms/javascript/react/#capturing-react-render-errors
const BugsnagErrorBoundary: React.FC<ErrorBoundaryProps> =
    getReactPlugin().createErrorBoundary(React);

/**
 * A default fallback component for the {@link ErrorBoundary} component. An optional message
 * parameter can be supplied if you wish to change the default error message. This parameter
 * should be used sparingly to maintain consistency in our error messages.
 * @param message
 */
export const DefaultFallback: React.FC<DefaultFallbackComponentProps> = ({
    message = "An error occurred",
    className,
}) => {
    return (
        <div className={`error-boundary__fallback ${className}`}>
            <Paragraph>{message}</Paragraph>
        </div>
    );
};

/**
 * A component that serves as a backstop for JS errors in React. React's default action when
 * encountering an error is to unmount the component, which could lead to an undesirable UI. This
 * implementation uses Bugsnag's ErrorBoundary which integrates with our existing Bugsnag bug
 * catching and reporting tools.
 *
 * This component wraps around child components and prevents an error from propagating further up
 * the component tree. Additionally, a {@link DefaultFallback} component will render instead of the
 * child components in the event of an error. You can create your own custom FallbackComponent as well.
 * The FallbackComponent is passed the clearError function by the ErrorBoundary which allows us to
 * re-render the child tree.
 *
 * Note that the ErrorBoundary will not catch errors for:
 *  - Event handlers
 *  - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 *  - Server side rendering
 *  - Errors thrown in the ErrorBoundary itself (rather than its children)
 *
 * @param FallbackComponent
 * @param onError
 * @param children
 */
export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
    FallbackComponent = DefaultFallback,
    children,
}) => {
    return (
        <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
            {children}
        </BugsnagErrorBoundary>
    );
};
