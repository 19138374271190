import Base = require("Everlaw/Base");
import Dialog = require("Everlaw/UI/Dialog");
import Document = require("Everlaw/Document");
import Dom = require("Everlaw/Dom");
import NewMessage = require("Everlaw/Messaging/NewMessage");
import NewSearchMessage = require("Everlaw/Messaging/NewSearchMessage");
import NewSelectedSearchMessage = require("Everlaw/Messaging/NewSelectedSearchMessage");
import Rest = require("Everlaw/Rest");
import SearchResult = require("Everlaw/SearchResult");
import SearchObjectPermission = require("Everlaw/Messaging/SearchSharing/SearchObjectPermission");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import Str = require("Everlaw/Core/Str");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");

/**
 * Convenience function that dispatches to the correct message/composer based on attachment, so the
 * caller doesn't need to worry about it.
 */
export function compose(
    messageParams: NewMessage.Params = {},
    composerParams: NewMessage.ComposerParams = {},
): Promise<NewMessage.Composer> {
    if (messageParams.attachment && messageParams.attachment instanceof SearchResult) {
        if (isNewSelectedSearchParams(messageParams)) {
            return searchMessageComposer(messageParams, composerParams, NewSelectedSearchMessage);
        } else {
            const searchMessageParams = messageParams as NewSearchMessage.Params;
            searchMessageParams.searchObjectPermission = null;
            return searchMessageComposer(searchMessageParams, composerParams, NewSearchMessage);
        }
    }

    return NewMessage.compose(messageParams, composerParams);
}

/**
 * Compose a message to Everlaw support.
 */
export function support(params?: {
    attach?: Base.Object;
    body?: Dom.Content;
}): Promise<NewMessage.Composer> {
    return NewMessage.compose(Object.assign({ to: User.byName("Everlaw") }, params));
}

/**
 * Use this method for shared objects to generate a default subject line.
 */
export function share(
    messageParams: NewMessage.Params,
    composerParams: NewSelectedSearchMessage.ComposerParams = {},
): Promise<NewMessage.Composer> {
    if (isNewSelectedSearchParams(messageParams)) {
        messageParams.subject = getDefaultSelectedShareSubject(composerParams.numActive);
        composerParams.subsetName = getDefaultSelectedShareTitle(
            messageParams.docIds,
            messageParams.fromAll,
            composerParams.numActive,
        );
    } else {
        messageParams.subject = getDefaultShareSubject(messageParams.attachment);
    }

    composerParams.dialogTitle = getShareDialogTitle(messageParams.attachment);
    composerParams.submitText = getShareDialogSubmitText();
    return compose(messageParams, composerParams);
}

function isNewSelectedSearchParams(obj: unknown): obj is NewSelectedSearchMessage.Params {
    return (
        typeof obj === "object"
        && obj !== null
        && "docIds" in obj
        && (obj as NewSelectedSearchMessage.Params).docIds?.length > 0
    );
}

export function getDefaultShareSubject(attachment: Base.Object): string {
    const classDisplay = ShareableObject.getClassDisplayName(attachment);
    return `${User.me.display()} has shared the ${classDisplay} ${attachment.display()} with you`;
}
export function getShareDialogTitle(attachment: Base.Object): string {
    const classDisplay = ShareableObject.getClassDisplayName(attachment).toLowerCase();
    return `Share ${classDisplay}`;
}

export function getDefaultSelectedShareSubject(numSelected: number): string {
    const displayNumber =
        numSelected >= 1000 && numSelected % 1000 > 0
            ? "~" + Util.displayNumberAbbr(numSelected)
            : Util.displayNumberAbbr(numSelected);

    return `${User.me.display()} has shared ${displayNumber} Documents with you`;
}

export function getDefaultSelectedShareTitle(
    docIds: number[],
    fromAll: boolean,
    numSelected: number,
) {
    let batesNumbers = [];

    if (!fromAll) {
        batesNumbers = docIds
            .map((id) => Base.get(Document, id)?.beginBates)
            // filter out potential null values
            .filter((str) => str);
    } else {
        const docIdSet = new Set(docIds);
        batesNumbers = Base.get(Document)
            .filter((doc) => !docIdSet.has(doc.id))
            .map((doc) => doc?.beginBates)
            // filter out potential null values
            .filter((str) => str);
    }

    /*
    "or..." case is when the docs in searchDocStore aren't all the docs being shared (we can't fetch them all
    for performance reasons).
     */
    const allDocsFetched = batesNumbers.length >= numSelected;
    const wouldOverflow = (string) => string.length > 135;

    let batesString = allDocsFetched
        ? Str.arrayToStringList(batesNumbers, "or")
        : `Bates ${batesNumbers.join(", ")} or...`;

    // If the current batesString would ~overflow tooltip, ellipsize the bates numbers.
    if (wouldOverflow(batesString)) {
        const ellipsizedBates = batesNumbers.map((batesNum) => batesNum.split(".")[0]);
        batesString = allDocsFetched
            ? Str.arrayToStringList(ellipsizedBates, "or")
            : `Bates ${ellipsizedBates.join(", ")} or...`;

        // If ellipsized bates would still overflow, modify string to end with "or ..."
        if (wouldOverflow(batesString)) {
            const lastCommaToInclude = batesString.slice(0, 130).lastIndexOf(",");
            batesString = batesString.slice(0, lastCommaToInclude + 1) + " or...";
        }
    }

    return batesString;
}

export function getShareDialogSubmitText(): string {
    return "Share";
}

/**
 * Create a NewSearchMessage or NewSelectedSearchMessage and its respective composer. In general, you should call
 * MessageComposer.compose() rather than calling this directly. Returns a promise that resolves
 * when the composer is constructed (since we need to first retrieve info from the server).
 */

export function searchMessageComposer(
    messageParams: NewSearchMessage.Params,
    composerParams: NewMessage.ComposerParams = {},
    MessageType: typeof NewSearchMessage,
): Promise<NewMessage.Composer> {
    return new Promise<NewMessage.Composer>((resolve, reject) => {
        Rest.get("messages/getSearchObjectPermissions.rest", {
            searchId: messageParams.attachment.id,
        }).then((data: SearchObjectPermission.Params) => {
            let composer: NewSearchMessage.Composer = null;
            const searchObjectPermission = new SearchObjectPermission(data);
            if (!messageParams.canRemoveAttachment && searchObjectPermission.hasInvalidTerms()) {
                // The user is unable to share the search and unable to remove the attachment,
                // so just display a dialog message and bail, rather than showing the composer.
                Dialog.ok("Unable to share search", [
                    "The search ",
                    Dom.span({ class: "semi-bold" }, messageParams.attachment.display()),
                    " contains invalid search terms.",
                    Dom.br(),
                    Dom.br(),
                    "Please refine the search terms to share this search.",
                ]);
            } else {
                messageParams.searchObjectPermission = searchObjectPermission;
                const message = new MessageType(messageParams);
                composer = new MessageType.Composer(message, composerParams);
            }
            resolve(composer);
        }, reject);
    });
}
