import * as UploadSource from "Everlaw/UploadSource";
import * as Rest from "Everlaw/Rest";
import { S3Source, S3SourceRestData } from "Everlaw/Upload/S3Source";
import { Id } from "Everlaw/User";

export interface DocumentS3SourceRestData extends S3SourceRestData {
    s3Key: string;
    sha1Hash?: string;
    uploadSource: string;
    uploadTaskId?: number;
    lastActive?: Activity;
}

export enum LocalStatus {
    QUEUED,
    ONGOING,
    ERROR,
    STOPPED,
    COMPLETED,
}

export interface Activity {
    userId: Id;
    timestamp: number;
}

/**
 * Contains upload config and S3 source file information for a file uploaded
 * through Native Upload or File Transfers.
 * A front-end correspondence of DocumentSource.java.
 */
export abstract class DocumentS3Source extends S3Source {
    s3Key: string;
    sha1Hash: string;
    uploadSource: UploadSource;
    uploadTaskId: number;
    lastActive: Activity;
    foundFiles: number;
    localStatus: LocalStatus;
    abstract setDirInfo(): void;

    override _mixin(data: DocumentS3SourceRestData): void {
        super._mixin(data);
        this.uploadSource = data.uploadSource as UploadSource;
    }

    signPart(partNumber: number): Promise<string> {
        return Rest.post("processing/signPart.rest", {
            s3Key: this.s3Key,
            s3UploadId: this.s3UploadId,
            partNumber,
        });
    }

    setSize(size: number, sha1: string, loaded: boolean): void {
        this.length = size;
        this.sha1Hash = sha1;
        if (loaded) {
            this.setDirInfo();
        }
    }

    isQueued(): boolean {
        return this.localStatus === LocalStatus.QUEUED;
    }

    isOngoing(): boolean {
        return this.localStatus === LocalStatus.ONGOING;
    }

    isQueuedOrOngoing(): boolean {
        return this.isQueued() || this.isOngoing();
    }

    isError(): boolean {
        return this.localStatus === LocalStatus.ERROR;
    }
}

/**
 * For local directories, once we have the size and hash, make sure to save them on the backend
 * so we can check when resuming.
 */
export function setDirInfo(source: DocumentS3Source, isProcessed: boolean): void {
    if (source.uploadSource === UploadSource.LOCAL_DIR) {
        Rest.post("processing/setDirInfo.rest", {
            size: source.length,
            isProcessed,
            sourceId: source.id,
            sha1: source.sha1Hash,
        });
    }
}
