import BasicRadio = require("Everlaw/UI/BasicRadio");
import Dom = require("Everlaw/Dom");
import NewMessage = require("Everlaw/Messaging/NewMessage");
import NewSearchMessage = require("Everlaw/Messaging/NewSearchMessage");
import Recipient = require("Everlaw/Recipient");
import Rest = require("Everlaw/Rest");
import Util = require("Everlaw/Util");
import {
    getDefaultShareSubject,
    getDefaultSelectedShareSubject,
} from "Everlaw/Messaging/MessageComposer";

class NewSelectedSearchMessage extends NewSearchMessage {
    fromAll: boolean;
    docIds: number[];
    shareOptionSelect: BasicRadio;
    constructor(params: NewSelectedSearchMessage.Params) {
        super(params);
        this.fromAll = params.fromAll;
        this.docIds = params.docIds;
    }

    override canReceiveAttachment(recipient: Recipient): boolean {
        // On creation of the message, this is fired before shareOptionSelect is instantiated.
        // The default shareOption is "subset", so we want the first return statement to be hit.
        if (!this.shareOptionSelect || this.isSubsetSearch()) {
            return !this.searchObjectPermission.shouldLockRecipient(recipient, true);
        } else {
            return !this.searchObjectPermission.shouldLockRecipient(recipient);
        }
    }

    protected override _doSend(params: NewMessage.SendParams) {
        if (!this.isSubsetSearch()) {
            super._doSend(params);
        } else {
            Rest.post("messages/constructAndShareSearch.rest", {
                replyTo: this.inReplyTo && this.inReplyTo.id,
                recipients: params.recipients,
                text: params.text,
                subject: params.subject,
                attachmentId: this.attachment.id,
                attachmentClass: this.attachment.className,
                attachmentPerm: params.attachmentPerm,
                extraShareParams: params.extraShareParams,
                fromAll: this.fromAll,
                docIds: this.docIds,
            }).then((data) => this._handleDataSuccess(data));
        }
    }

    isSubsetSearch(): boolean {
        return this.shareOptionSelect.getSelectedId() === "subsetSearch";
    }
}

module NewSelectedSearchMessage {
    export interface Params extends NewSearchMessage.Params {
        fromAll: boolean;
        docIds: number[];
    }

    export interface ComposerParams extends NewMessage.ComposerParams {
        // Number of selected documents
        numActive?: number;
        // Used when sharing specific documents
        subsetName?: string;
    }

    export class Composer extends NewSearchMessage.Composer {
        private static readonly MAX_SEARCH_SIZE = 10000;
        private shareOptionSelect: BasicRadio;
        private numSelected: number;
        private isRestrictedSender: boolean;
        private subsetName: string;

        constructor(
            public override message: NewSelectedSearchMessage,
            params: ComposerParams,
        ) {
            super(message, params);
            this.subsetName = params.subsetName;
            this.numSelected = params.numActive;
            this.buildShareOptionContainer();
            this.hideAttachmentWarning();
            Dom.setContent(this.messageAttachmentName, this.subsetName);
            this.isRestrictedSender =
                !!this.message.searchObjectPermission.getShareableObjectsSenderCannotShare();
            this.checkForValidSearch();
        }

        protected buildShareOptionContainer(): void {
            const displayNumber =
                this.numSelected >= 1000 && this.numSelected % 1000 > 0
                    ? "~" + Util.displayNumberAbbr(this.numSelected)
                    : Util.displayNumberAbbr(this.numSelected);
            const shareOptions = [
                {
                    id: "subsetSearch",
                    display: `Share ${displayNumber} selected documents`,
                    leftMargin: "10px",
                    disabledTooltip:
                        "The number of selected documents is too large to be shared via this option",
                },
                {
                    id: "fullSearch",
                    display: "Share the entire search results table",
                    leftMargin: "10px",
                },
            ];
            this.shareOptionSelect = new BasicRadio(shareOptions, false);
            this.shareOptionSelect.select("subsetSearch");
            this.shareOptionSelect.onChange = (selected) => {
                let subjectName: string;
                if (selected.id === "subsetSearch") {
                    subjectName = getDefaultSelectedShareSubject(this.numSelected);
                    Dom.setContent(this.messageAttachmentName, this.subsetName);
                    this.hideAttachmentWarning();
                    this.revertShareButtonAndPermWarning();
                } else {
                    subjectName = getDefaultShareSubject(this.message.attachment);
                    Dom.setContent(this.messageAttachmentName, this.message.attachment.display());
                    this.showAttachmentWarning(this.buildAttachmentWarningMessage());
                    this.updateSearchObjectSharing();
                    this.onObjGrantingChange();
                }
                this.subjectWidget.setValue(subjectName);
                Dom.setContent(this.subjectEditorHeader, subjectName);
                // Only rebuild the widget in the case that the sender has shareable objects that
                // cannot be shared. Otherwise, the recipients widget remains the same between options.
                if (this.isRestrictedSender) {
                    this.buildRecipientsWidget();
                }
            };

            this.toDestroy.push(this.shareOptionSelect);

            const searchOptions = Dom.div(
                { class: "title-and-options-container" },
                Dom.span({ class: "share-option-container__label" }, "Share options"),
                Dom.div(
                    { class: "search-options-and-view-container" },
                    Dom.div({ class: "share-options-container" }, this.shareOptionSelect.getNode()),
                ),
            );
            Dom.place(searchOptions, this.subjectContainer, "before");
            this.message.shareOptionSelect = this.shareOptionSelect;
        }

        protected hideAttachmentWarning(): void {
            Dom.hide(this.attachmentWarningContainer);
            Dom.hide(this.nonAttachmentRecipientsDiv);
        }

        protected checkForValidSearch(): void {
            if (this.numSelected < Composer.MAX_SEARCH_SIZE) {
                return;
            } else {
                this.shareOptionSelect.select("fullSearch");
                this.shareOptionSelect.setDisabled("subsetSearch", true);
                this.shareOptionSelect.onChange(
                    this.shareOptionSelect.elements[1],
                    this.shareOptionSelect.elements[0],
                );
            }
        }

        protected override setExtraSendParams(params: NewMessage.SendParams) {
            super.setExtraSendParams(params);
            params.shareSubset = this.shareOptionSelect.getSelectedId() === "subsetSearch";
            params.subsetName = this.subsetName;
            return params;
        }

        protected override onRecipientChange(recipient: Recipient, added: boolean) {
            const changeShareButton = this.shareOptionSelect.getSelectedId() === "fullSearch";
            super.onRecipientChange(recipient, added, changeShareButton);
            if (
                this.message.nonAttachmentRecipients.size
                && this.shareOptionSelect.getSelectedId() === "fullSearch"
            ) {
                Dom.show(this.attachmentWarningContainer);
            } else if (
                !this.message.nonAttachmentRecipients.size
                && !this.attachmentWarningMsgDiv.textContent
            ) {
                Dom.hide(this.attachmentWarningContainer);
            }
        }

        protected override updateSearchObjectSharing(): void {
            if (!this.shareOptionSelect) {
                Dom.hide(this.searchObjectSharingContainer);
            } else if (this.shareOptionSelect.getSelectedId() === "fullSearch") {
                super.updateSearchObjectSharing();
            }
        }

        protected revertShareButtonAndPermWarning(): void {
            this.dialog._submitButton.setContent("Share");
            Dom.replaceClass(this.dialog._submitButton, "one-width", "obj-perm-grant-button");
            Dom.hide(this.searchObjectSharingContainer);
        }
    }
}

export = NewSelectedSearchMessage;
