import XRegExp = require("xregexp");

/**
 * These should be the same as PartialSelectionType.java
 * The order is used for determining {@link PartialSelector} order
 */
export enum PartialSelectionType {
    FULL = "FULL",
    USERNAME = "USERNAME",
    USERNAME_NOT_FIRST_CHAR = "USERNAME_NOT_FIRST_CHAR",
    DOMAIN = "DOMAIN",
    NOT_LAST_FOUR_DIGITS = "NOT_LAST_FOUR_DIGITS",
    NOT_LAST_FOUR_CHARS = "NOT_LAST_FOUR_CHARS",
    NOT_LAST_BYTE = "NOT_LAST_BYTE",
    NOT_FIRST_CHAR = "NOT_FIRST_CHAR",
}

// looks for two letters followed by two numbers followed by 4-30 alphanumeric separated by
// whitespace or dashes every 4 characters
const LENIENT_IBAN_REGEX =
    "[A-Za-z]{2}[0-9]{2}[\\s-]?([0-9a-zA-Z]{4}[\\s-]?){1,7}[0-9a-zA-Z]{1,3}?";

const LENIENT_NINO_REGEX = "[A-Za-z]{2}\\s*([0-9]{2}\\s*){3}[A-Za-z]";

/**
 * matchRegex: the regex to test string queries to see if we should display a partial selection option.
 * We're keeping these pretty lenient since the false positive case is not harmful (unlike false negative hits)
 * NOTE: We use these regexes instead of the "regexes" in {@link NAMED_PATTERNS} because those work on the
 * tokenized terms in the index, while this works on the pure original text that includes spaces, newlines, etc
 *
 * selectionRegex: the regex that determines what part of the text to redact. Should be the same as the regexes in
 * PartialSelectionType.java (with some exceptions due to browser regex limitations). Currently only
 * used to preview the redaction in PartialSelector.
 *
 * We use [\S\s] to mean every character since . does not include new lines.
 */
export const SEL_TYPE_INFO_MAP: {
    [key in PartialSelectionType]: { display: string; matchRegex: RegExp; selectionRegex: RegExp };
} = {
    [PartialSelectionType.FULL]: {
        display: "Full redaction",
        matchRegex: /[\S\s]*/,
        selectionRegex: /.*/g,
    },
    [PartialSelectionType.USERNAME]: {
        display: "Redact username",
        matchRegex: /\S+@\S+\.\S+/,
        selectionRegex: /[\S\s]+(?=@)/g,
    },
    /**
     * NOTE: The selection regex was modified from the backend regex and does not use positive lookbehinds
     * because we want to support Safari users <16.4. There are some edge cases where accented
     * characters do not behave as expected.
     */
    [PartialSelectionType.USERNAME_NOT_FIRST_CHAR]: {
        display: "Redact username except first character",
        matchRegex: /\S{2,}@\S+\.\S+/,
        selectionRegex: /(?!\b)[^']+(?=@)/g,
    },
    /**
     * NOTE: The selection regex was modified from the backend regex and does not use positive lookbehinds
     * because we want to support Safari users <16.4.
     */
    [PartialSelectionType.DOMAIN]: {
        display: "Redact domain",
        matchRegex: /\S+@\S+\.\S+/,
        selectionRegex: /(?![\S\s]*@)[\S\s]+/g,
    },
    [PartialSelectionType.NOT_LAST_FOUR_DIGITS]: {
        display: "Redact all except last four digits",
        matchRegex: /[\S\s]+(\d[\W]*){4}$/,
        selectionRegex: /[\S\s]*(?=(\d\D*){4}$)/g,
    },
    [PartialSelectionType.NOT_LAST_FOUR_CHARS]: {
        // currently only used for IBANs and NINOs
        display: "Redact all except last 4 characters",
        matchRegex: new RegExp(`^${LENIENT_IBAN_REGEX}|${LENIENT_NINO_REGEX}$`),
        selectionRegex: /[\S\s]*(?=(\w\W*){4}$)/g,
    },
    [PartialSelectionType.NOT_LAST_BYTE]: {
        display: "Redact all except last byte",
        matchRegex: /^[0-9]{1,3}[.][0-9]{1,3}[.][0-9]{1,3}[.][0-9]{1,3}$/, // IPv4 only
        selectionRegex: /[\d.\s]+\.(?=[\d\s])/g,
    },
    /**
     * We use the XRegExp library here to get access to \p{L} which should match all "letter" characters.
     * We use this instead of something like \w because we'd like to allow this partial redaction option
     * for queries like "André" or even non-latin words.
     *
     * NOTE: The selection regex was modified from the backend regex and does not use positive lookbehinds
     * because we want to support Safari users <16.4. There are some edge cases where accented
     * characters do not behave as expected.
     */
    [PartialSelectionType.NOT_FIRST_CHAR]: {
        display: "Redact all except first characters",
        matchRegex: XRegExp("[\\p{L}0-9@.]{2,}"),
        selectionRegex: /(?!\b)\S+/g,
    },
};
