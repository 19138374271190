import { EverColor, Icon, MarkedText } from "design-system";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import type { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";
import { getNavigationPageUrl } from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import * as React from "react";
import { FC } from "react";

interface RecommendationDisplayProps {
    recommendation: Recommendation;
    /**
     * If passed, we'll highlight the text in the display that corresponds to the
     * {@link Recommendation#displayName}.
     */
    filterValue?: string;
}

export function onRecommendationClick(recommendationName: keyof typeof Recommendations): void {
    const recommendation = Recommendations[recommendationName];
    const postUrl = Project.url(
        Project.CURRENT.id,
        "recommendation/activateProjectRecommendation.rest",
    );
    Rest.post(postUrl, {
        recommendation: recommendationName,
        navigationPage: recommendation.navigationPage,
    }).then((id?: number) => {
        const navigationPage = getNavigationPageUrl(recommendation.navigationPage, id?.toString());
        if (window.location.pathname === navigationPage.pathname) {
            window.location.hash = navigationPage.hash;
            window.location.reload();
        } else {
            window.location.href = navigationPage.href;
        }
    });
}

/**
 * Use to sort an array of {@link Recommendations} by descending, last modified order.
 */
export function recommendationSort(a: Recommendation, b: Recommendation): number {
    return b.getLastModifiedUnsafe() - a.getLastModifiedUnsafe();
}

const displayClass = "recommendation-display-name";
const rowClasses = "recommendation-row flex-centered";
const iconAriaLabel = "Recommendation not viewed icon";
const iconColor = EverColor.GREEN_40;
export const TableRecommendationDisplay: FC<RecommendationDisplayProps> = ({
    recommendation,
    filterValue,
}) => {
    return (
        <div className={rowClasses}>
            {recommendation.isNotViewedUnsafe() && (
                <Icon.CircleFilled
                    aria-label={iconAriaLabel}
                    color={iconColor}
                    size={"6px"}
                    className="recommendation-row__not-viewed-icon--table-row"
                />
            )}
            <div className={displayClass}>
                <MarkedText textToMark={filterValue}>{recommendation.displayName}</MarkedText>
            </div>
        </div>
    );
};

export const MenuRowRecommendationDisplay: FC<RecommendationDisplayProps> = ({
    recommendation,
}) => {
    return (
        <div className={rowClasses + " clickable-row"}>
            {recommendation.isNotViewedUnsafe() && (
                <Icon.CircleFilled
                    aria-label={iconAriaLabel}
                    color={iconColor}
                    size={"4px"}
                    className="recommendation-row__not-viewed-icon--help-menu-row"
                />
            )}
            <div className={displayClass}>{recommendation.displayName}</div>
        </div>
    );
};
