import Base = require("Everlaw/Base");
import Perm = require("Everlaw/PermissionStrings");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");

class HomepageFolder extends Base.SecuredObject {
    get className() {
        return "HomepageFolder";
    }
    override id: HomepageFolder.Id;
    owner: User;
    name: string;
    parentId: HomepageFolder.Id;
    shared = false;
    objectPerms: Base.Secured;
    created: number;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        this.name = params.name;
        this.shared = !!params.shared;
        this.objectPerms = params.objectPerms;
        if (params.owner) {
            this.owner = Base.get(User, params.owner);
        }
        this.parentId = params.parent;
        this.created = params.created;
    }
    override display() {
        return this.name;
    }
    rename(name: string, callback?: (correctedName: string) => void, error?: Rest.Callback) {
        Rest.post("renameFolder.rest", {
            id: this.id,
            name: name,
        }).then(
            (data) => {
                this._mixin(data);
                Base.publish(this);
                callback && callback(this.name);
            },
            (e) => {
                if (error) {
                    error(e);
                } else {
                    throw e;
                }
            },
        );
    }
    canShare(): boolean {
        // TODO This is a work-around for the current issue that a user could potentially have
        // permissions on the folder without having any object permissions (if the folder was
        // shared as part of a message forwarding operation). This can be cleaned up once we have
        // a solution for that issue.
        const isOrgAdmin = User.me.hasOrgAdminAccess();
        const isOwner = this.owner === User.me;
        const hasObjectPerms =
            this.objectPerms && this.objectPerms.security && !!this.objectPerms.security.length;
        return isOrgAdmin || (User.me.can(Perm.ADMIN, this) && (isOwner || hasObjectPerms));
    }
    nestingDepth(): number {
        let depth = 1;
        let currFolder;
        currFolder = this;
        while (currFolder?.parentId) {
            depth += 1;
            currFolder = Base.get(HomepageFolder, currFolder.parentId);
        }
        return depth;
    }
    isAncestorOf(subFolder: HomepageFolder): boolean {
        while (subFolder) {
            if (this.id === subFolder.id) {
                return true;
            }
            const id = subFolder.parentId;
            subFolder = id && Base.get(HomepageFolder, id);
        }
        return false;
    }
}

module HomepageFolder {
    export type Id = number & Base.Id<"HomepageFolder">;
}

export = HomepageFolder;
