import Base = require("Everlaw/Base");
import Is = require("Everlaw/Core/Is");
import Preference = require("Everlaw/Preference");
import ResultsTableView = require("Everlaw/ResultsTableView");
import SavedResultsTableView = require("Everlaw/SavedResultsTableView");

/**
 * Sam Bernstein
 *
 * Helper module that factors out certain results table view utility functions from
 * SearchResultsView.ts to void circular dependencies.
 */
module SearchResultsViewUtil {
    export function getProjectDefaultView() {
        const views = Base.get(SavedResultsTableView).filter((sv) =>
            SavedResultsTableView.isDefault(sv.name),
        );
        return views.length ? views[0] : null;
    }

    export function lastViewId() {
        const lastUsedView = Preference.SEARCH.visibleColumns.get();
        return Is.number(lastUsedView) ? <number>lastUsedView : null;
    }

    export function findCorrespondingSavedView(view: SavedResultsTableView | ResultsTableView) {
        if (view instanceof SavedResultsTableView) {
            return view;
        } else {
            const match = Base.get(SavedResultsTableView).filter((v) => v.view.id === view.id);
            if (match.length) {
                return match[0];
            }
            return null;
        }
    }

    export function getCurrentSavedViewId() {
        const viewId = SearchResultsViewUtil.lastViewId();
        if (viewId) {
            const view = Base.get(ResultsTableView, viewId);
            const savedView = SearchResultsViewUtil.findCorrespondingSavedView(view);
            return savedView ? savedView.id : null;
        } else {
            return null;
        }
    }

    export function getVisibleColumnsFromPref() {
        let searchStructure = [];
        let saveRawColumnsToLastUsed = false;
        let resultsTableView: ResultsTableView | null = null;
        const lastUsedView = Preference.SEARCH.visibleColumns.get();
        if (Is.number(lastUsedView)) {
            const view = Base.get(ResultsTableView, <number>lastUsedView);
            if (view) {
                searchStructure = view.getColumnsWithWidths();
                resultsTableView = view;
            }
        } else if (Is.array(lastUsedView)) {
            if (lastUsedView.length) {
                searchStructure = lastUsedView;
            } else {
                const view = getProjectDefaultView();
                if (view) {
                    searchStructure = view.getColumnsWithWidths();
                    resultsTableView = view.view;
                    Preference.SEARCH.visibleColumns.setUserValue(resultsTableView.id);
                }
            }
        }
        // If no saved view in pref, no raw columns in pref, and no project default view yet
        if (!searchStructure.length) {
            saveRawColumnsToLastUsed = true;
        }
        return { searchStructure, saveRawColumnsToLastUsed, view: resultsTableView };
    }
}

export = SearchResultsViewUtil;
